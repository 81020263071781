<template>
  <div>
    <!-- Header start -->

    <header id="header" :style="'background-image: url('+require('@/assets/landingPage/test1.jpg')+')'">
      <v-container>
        <v-row class="text-sm-left text-center pb-16 align-center ">
          <v-col offset="" cols="12" md="7" lg="6" class="ps-md-0 ps-0 ps-sm-12 ps-xl-12 ps-5 pe-5 align-center"
                 id="mainTitle">
            <h2 class="text-sm-h2 text-h4 text-md-h3 pb-3">Plane Deine Hochzeiten <span
                class="primary--text" id="headLineChange">{{ currentHeadline }}</span></h2>
            <h1 class="text-md-h5 text-h6 font-weight-regular">Die Software für professionelle
              Hochzeitsplaner *innen</h1>

            <div class="pt-5">
              <p class="subtitle-1">Mit Wedding-Flow planst und verwaltest Du Deine Hochzeiten auf einem
                professionellen Niveau.</p>
              <v-container class="pa-0 ma-0">
                <v-row class="pa-0 ma-0 align-center">
                  <v-col class="pa-0 ma-0" cols="12" md="6">
                    <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
                           @click="clickDemo">
                      Demo vereinbaren
                      <v-icon class="ps-2">
                        mdi-calendar
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-0 ma-0 ps-md-3 pt-3 pt-md-0" cols="12" md="6">
                    <a href="https://app.wedding-flow.de/#/registrieren" target="_blank">

                      <v-btn class=" text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
                             @click="clickTest">
                        Kostenlos testen

                        <v-icon class="ps-2">
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </a>

                  </v-col>

                </v-row>
              </v-container>


            </div>
          </v-col>
          <v-col class="mt-16 pt-md-16 pa-8 text-center">
            <a href="https://www.provenexpert.com/wedding-flow/?utm_source=Widget&utm_medium=Widget&utm_campaign=Widget"
               title="Kundenbewertungen &amp; Erfahrungen zu Wedding-Flow. Mehr Infos anzeigen." target="_blank"
               rel="noopener noreferrer"><img
                src="https://images.provenexpert.com/81/c0/886696a03a2eee69f693fac4a207/widget_recommendation_465_0.png?t=1706703292388"
                alt="Kundenbewertungen &amp; Erfahrungen zu Wedding-Flow. Mehr Infos anzeigen."
                style="border:0; width: 100%; max-width: 400px"/></a>
          </v-col>
        </v-row>
      </v-container>
    </header>
    <!-- Header end -->
    <!-- Vorteile start -->
    <div class="backGroundGrey">

      <v-container class="containerPadding" id="vorteile" fluid>
        <v-row class="justify-center align-center text-center">
          <v-col cols="12" sm="6" class="ps-4 pe-4 pt-6">
            <h3 class="pb-2 text-subtitle-1">Alles im Überblick</h3>
            <h4 class="pb-8 text-h5">Deine Brautpaare, Hochzeiten und vieles mehr an einem Ort. Steigere
              Deine
              Effektivität und verwalte Deine Daten professionell.</h4>

          </v-col>
          <v-col cols="12" sm="10" md="8" class="ps-4 pe-sm-16 pt-6 text-center">
            <v-img
                :src="require('@/assets/landingPage/desk.png')"
                position="bottom"
                max-width=""
                height="auto"
                class="d-inline-flex rounded-xl"/>
          </v-col>

        </v-row>
      </v-container>
    </div>

    <div class="backGroundGrey">
      <v-container class="containerPadding" id="vorteile">
        <v-row class="justify-center align-center text-center text-sm-start">
          <v-col cols="12" sm="6" class="ps-4 pe-4 pt-6 ">
            <h2 class="pb-2 text-subtitle-1">Für Hochzeitsplaner *innen entwickelt</h2>
            <h4 class="pb-8 text-h5">
              Praktische Funktionen, die Dich bei Deinem Alltag als Hochzeitsplaner*in unterstützen.</h4>
            <a @click="$router.push('/funktionen')">
              <v-list-item class="hover">
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-cogs
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Funktionen entdecken
                    <v-icon class="ps-2">
                      mdi-arrow-right
                    </v-icon>
                  </v-list-item-title>
                  <v-list-item-subtitle>Aufgaben, Termine, Budgetpläne, Tagesabläufe uvm.
                  </v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
            </a>
          </v-col>
          <v-col cols="6" sm="3" class="pa-3 pa-md-12  text-center">
            <Doughnut :chartData="chartData"></Doughnut>
          </v-col>
          <v-col cols="6" sm="3" class="pa-3 pa-md-12 text-center">
            <Doughnut :chartData="chartData2"></Doughnut>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="backGroundGrey">
      <v-container class="containerPadding" id="vorteile">
        <v-row class="justify-center align-center text-center text-sm-start">
          <v-col cols="12" sm="6" class="order-2 order-sm-1 pe-0 pe-sm-16 pt-6  text-center">
            <v-img
                :src="require('@/assets/landingPage/mobileWed2.png')"
                position="bottom"
                max-width="250px"
                height="auto"
                class="d-inline-flex rounded-xl"/>
          </v-col>
          <v-col cols="12" sm="6" class="order-1 order-sm-2 ps-4 pe-4 pt-6  ">
            <h2 class="pb-2 text-subtitle-1">Überall dabei
            </h2>
            <h4 class="pb-8 text-h5">
              Notiere wichtige Informationen direkt übers Smartphone oder präsentiere
              Deine Planung auf Deinem Tablet.
            </h4>
          </v-col>
        </v-row>
      </v-container>
    </div>


    <!-- Funktionen start -->
    <div style="background-color: #FAFAFA">
      <v-container class="containerPadding " id="funktionen">
        <v-row class="align-center">
          <v-col cols="12" lg="7" class="pa-md-8">
            <h2 class="pb-2 text-subtitle-1">Funktionen
            </h2>
            <h4 class="pb-8 text-h4">
              Funktionen und Tools, um Dich bei Deinem Alltag zu unterstützen
            </h4>
            <v-row class="pt-9">
              <v-col cols="12" sm="6" class="pe-16 " v-for="funktion of this.funktionen"
                     :key="funktion.title">
                <v-icon color="tertiary" size="50">
                  {{ funktion.icon }}
                </v-icon>
                <h3 class="pb-2 pt-4 text-h6">{{ funktion.title }}</h3>
                <p>{{ funktion.content }}</p>
              </v-col>

              <v-col cols="12" sm="6" class="pe-16 hover">
                <a @click="$router.push('/funktionen')">
                  <v-icon color="primary" size="50">
                    mdi-arrow-right
                  </v-icon>
                  <h3 class="pb-2 pt-4 text-h6">Mehr entdecken</h3>
                  <a>Alle Funktionen von Wedding-Flow</a></a>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="5" class="pa-8 te
                    xt-center" style="position: relative">
            <v-img
                :src="require('@/assets/landingPage/funtion.svg')"
                position="bottom"
                max-width="100%"
                class="d-inline-flex"/>

          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Funktionen end -->


    <!-- Vorteile end -->
    <div class="backGroundGrey">
      <v-container class="containerPadding" id="about">
        <v-row class="text-center justify-center align-center">
          <v-col cols="12" md="4">
            <a href="https://app.wedding-flow.de/#/registrieren" target="_blank">
              <v-btn @click="clickTest" class=" text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
              >
                Kostenlos testen
                <v-icon class="ps-2">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </a>

          </v-col>
          <v-col cols="12" md="2" lg="1">
            oder
          </v-col>
          <v-col cols="12" md="4">
            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" @click="clickDemo">
              Jetzt Demo buchen
              <v-icon class="ps-2">
                mdi-calendar
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Contact and Demo start -->
    <div class="" style="background-color: #FAFAFA">
      <v-container class="containerPadding" id="contact">
        <v-row class="text-center pb-8">
          <v-col>
            <h2 class="pb-2 text-subtitle-1 d-block">
              Kontakt
            </h2>
            <h4 class="pb-8 text-h4 d-block">
              Dein Ansprechpartner rund um Fragen zur Anwendung
            </h4>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="6" md="4" class="text-center ">
            <v-img
                :src="require('@/assets/landingPage/profilbild.png')"
                position="bottom"
                max-width="270px"
                width="70%"
                class="d-inline-flex rounded-circle"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" class="ps-md-8">
            <p>Bei offenen Fragen bin ich gerne für Dich erreichbar!</p>
            <h5 class="text-h6">Benedikt Binzer</h5>
            <p>
              <v-icon color="" size="20" class="scale">
                mdi-phone
              </v-icon>
              <a href="tel:+4915739456571">+49 1573 9456571 </a><br>
              <v-icon color="" size="20" class="scale pe-1">
                mdi-email
              </v-icon>
              <a href="mailto:benedikt@wedding-flow.de">benedikt@wedding-flow.de</a>
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="ps-md-8 pt-12 pt-md-0 text-center text-md-start">
            <h4 class="text-h5 pb-3">Demo vereinbaren</h4>
            <p>Buche gerne einen Termin und ich zeige Dir die Anwendung und die Vorteile, die sie
              Dir
              bietet.</p>
            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" @click="clickDemo">
              Termin buchen
            </v-btn>

          </v-col>
        </v-row>
      </v-container>

      <div class="backGroundGrey">
        <v-container class="containerPadding">
          <v-row class="justify-center align-center text-center">
            <v-col cols="12" md="3">
              <v-img
                  :src="require('@/assets/landingPage/climate.svg')"
                  max-width="70px"
                  width="100%"
                  class="d-inline-flex"
              />
            </v-col>
            <v-col cols="12" md="5" class="text-center text-md-start">
              <h2 class="pb-2 text-subtitle-1 d-block">
                Förderung der CO₂-Entnahme
              </h2>
              <h4 class="pb-8 text-h5 d-block">
                Erfahre wie wir gemeinsam mit Dir einen Teil dazu beitragen, dass CO₂ aus der Atmosphäre
                gefiltert wird.
              </h4>
              <div class="hover">
                <a target="_blank" href="https://climate.stripe.com/nGrMmy">Mehr Erfahren
                  <v-icon class="ps-2" color="primary">
                    mdi-arrow-right
                  </v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

    <v-dialog
        v-model="dialogDemo"
        :max-width="1200"
    >
      <iframe width="540" height="700vh"
              src="https://meet.sendinblue.com/benedikt-binzer?t=0&l=demo-weddingflow-mit-benedikt-binzer"
              frameborder="0" scrolling="auto" allowfullscreen
              style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; background-color: white"></iframe>
    </v-dialog>
    <v-dialog
        v-model="dialogNewsletter"
        :max-width="550"
    >
      <iframe width="540" height="700vh"
              src="https://575f9199.sibforms.com/serve/MUIEAEY4uCrZC7IS6TiN-zEAhHFT5bgGJvzh9RKBXR5uH9OtqUnD9yFSLQeNwBiNjpxU9MW2ccgZLEtjzld0s5aRCCWE3DN1ViC3hxv2uWII6tqM70j7HMYgocdpLg053rI-zfiGCQQGfsAnsRXkv-46QakG1aXeN3WVeUrtJNK3P26vCVtTOt9mBnGUb95DdEmNueo4-dPQbK-U"
              frameborder="0" scrolling="auto" allowfullscreen
              style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
    </v-dialog>
  </div>
</template>

<script>

const Doughnut = () => import("@/components/charts/Doughnut");

export default {
  name: 'Home',
  components: {Doughnut},

  data() {
    return {
      dialogContact: false,
      dialogNewsletter: false,
      contact: {
        contactName: ''
      },
      chartData: {
        labels: ["Noch verfügbar €", "Ausgegeben €"],
        datasets: [{
          borderWidth: 3,
          backgroundColor: [
            '#6D7E6D',
            '#CECAB7'
          ],
          data: [2400.52, 8544.56]
        }]
      },
      chartData2: {
        labels: ["Location €", "Dekoration €", "Live-Musik €"],
        datasets: [{
          borderWidth: 3,
          backgroundColor: [
            '#B19983',
            '#CECAB7',
            '#EDE9D4',
            '#CFC6C1',
            '#F1E7E2',
          ],
          data: [1498.50, 800.20, 1300.48]
        }]
      },
      dialogDemo: false,
      currentHeadline: 'effektiver',
      headLineChanges: ['effektiver', 'mit mehr Freude', 'übersichtlicher'],
      contactFields: [
        {
          title: 'Name',
          value: '',
          id: 0,
          type: 'string'
        },
        {
          title: 'Unternehmen (optional)',
          value: '',
          id: 1,
          type: 'string'
        },
        {
          title: 'E-Mail',
          value: '',
          id: 2,
          type: 'string'
        },
        {
          title: 'Telefon',
          value: '',
          id: 3,
          type: 'string'
        },
        {
          title: 'Nachricht (optional)',
          value: '',
          id: 3,
          type: 'string'
        },
        {
          title: 'Ich habe die Datenschutzerklärung gelesen und akzeptiere diese.',
          value: false,
          id: 4,
          type: 'boolean'
        },
        {
          title: 'Ich möchte per E-Mail über Angebote, Neuigkeiten und allem rund um das Produkt benachrichtigt werden.',
          value: false,
          id: 5,
          type: 'boolean'
        },
      ],
      vorteile: [
        {
          title: 'Alles im Überblick',
          content: 'Deine Brautpaare, Hochzeiten und vieles mehr an einem Ort. Steigere Deine Effektivität und verwalte Deine Daten professionell.',
          icon: 'mdi-account-eye',
        },
        {
          title: 'Für Hochzeitsplaner*innen entwickelt',
          content: 'Wir stellen Dir praktische Funktionen zur Verfügung, die Dich bei Deinem Alltag als Hochzeitsplaner*in unterstützen.',
          icon: 'mdi-ring',
        },
        {
          title: 'Überall nutzen',
          content: 'Notiere wichtige Informationen direkt übers Smartphone oder präsentiere Deine Planung direkt aus der Anwendung auf Deinem Tablet.',
          icon: 'mdi-tablet-cellphone',
        }],
      funktionen: [
        {
          title: 'Dashboard',
          content: 'Das wichtigste zuerst! Anstehende Termine und Hochzeiten, sowie offene Aufgaben auf einen Blick.',
          icon: 'mdi-post',
        },
        {
          title: 'Kunden und Dienstleister',
          content: 'Pflege Deine Kunden und Dienstleister. Verknüpfe Kontakte mit Hochzeiten oder Aufgaben und behalte alles im Blick.',
          icon: 'mdi-account-eye',
        },
        {
          title: 'Aufgabenverwaltung',
          content: 'Durch die ToDo-Liste und die Zuordnung von Aufgaben zu Hochzeiten, weißt Du immer was Du zu tun hast.',
          icon: 'mdi-format-list-checks',
        },
        {
          title: 'Terminkalender',
          content: 'Behalte Termine und Fristen immer im Auge. Viele Ereignisse werden automatisch in Deinen Kalender eingetragen.',
          icon: 'mdi-calendar',
        },
        {
          title: 'Dokumente und Rechnungen',
          content: 'Lade Dokumente, direkt an die entsprechende Hochzeit hoch und erstelle Rechnungen und Angebote mit wenigen Klicks.',
          icon: 'mdi-file',
        },

      ],
      steps: [
        {
          title: 'Registrieren',
          content: 'E-Mail Adresse eingeben, Passwort wählen und sofort starten.',
          number: '01',
          image: ''
        },
        {
          title: 'Daten einfügen',
          content: 'Erstelle Deine erste Hochzeit, lege erste Kunden an und pflege Deine Dienstleister.',
          number: '02',
          image: ''
        },
        {
          title: 'Planen',
          content: 'Verwalte Deine Aufgaben, erstelle Budgetpläne und plane mehrere Hochzeiten parallel.',
          number: '03',
          image: ''
        },
      ],

    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    hideDialogContact() {
      this.dialogContact = false
    },
    showDialogContact() {
      this.dialogContact = true
    },
    clickTest() {
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');
    },
    clickDemo() {
      this.dialogDemo = true
      // eslint-disable-next-line no-undef
      fbq('track', 'Schedule');

    }
  }
}

</script>
<style>
* {
  transition: 0.5s;
}

.testBTN {
  background-color: rgba(255, 255, 255, 0.7);
}

#headLineChange {
  transition: 1s;
}


.hover {
  transition: 0.5s;
}

.hover:hover {
  transform: translateY(-7px) !important;
  transition: 0.5s !important;
}

h2 {
  padding-bottom: 15px;
}

.stepNumber {
  font-size: 80px;
  font-family: Great Vibes, Great Vibes, sans-serif !important;
}

.bottomFixed {
  bottom: 20px;
  position: absolute;
  width: 100%;
  left: 0
}

header {
  height: 95vh;
  position: relative;
  background-size: cover;
  background-position: center left;
  background-attachment: fixed;

}


@keyframes fadeIn3 {
  0% {
    opacity: 0;
    margin-left: -300px;
  }
  100% {
    opacity: 1;
  }
}

.fadeSide {
  animation: fadeIn3 1s;

}

.startBtn {
  border-radius: 14px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

#mainTitle {
  padding-top: 18vh;
  animation: fadeIn3 1s;
}

.backGroundGrey {
  background-color: #F5F5F5;
}

.containerPadding {
  padding-top: 150px;
  padding-bottom: 150px
}

</style>
