<template>
  <v-app style="background-color: #F7F7F7" >
    <Navbar></Navbar>

    <v-main class="mainContent">
      <router-view />
    </v-main>

    <Footer></Footer>

  </v-app>
</template>

<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Brown Sugar";
  src: local("Brown Sugar"), url("./fonts/BrownSugar/BrownSugar.ttf") format("truetype");
}
*{
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  transition: .5s !important;
  max-width: 100vw;
}

.logo {
  font-family: "Brown Sugar", sans-serif;
}

.logo:hover{
  cursor: pointer;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a{
  text-decoration: none;
}
.backGroundGrey {
  background-color: #F3F4F3 ;
}
::-webkit-scrollbar {
  height: 12px;
  width: 10px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #6D7E6D;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-thumb:hover {
  background: #CECAB7;
}
.scale:hover {
  transform: scale(1.2);
}
.mainContent{
  min-height: 90vh;
}
.detailCard{
  padding: 5vh 5% ;
}
.fadeIn{
  animation: fadeIn 3s;
}
.fadeInFast{
  animation: fadeIn .5s;
}
.fadeInDelay{
  animation: fadeInDelay 1s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeInDelay {
  0% {opacity:0;}
  70% {opacity:0;}
  100% {opacity:1;}
}
</style>
<script>
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Navbar";

export default {
  components: {Navbar, Footer},
  data() {
    return {
      drawer: null,
      permanent: null,
    }
  },
  computed: {

  },
  mounted() {
    },
  watch:{
    $route (){
      if( window.innerWidth <= 1060) {
        this.drawer = false;
      }
    }
  },
  methods:{
    closeDrawer(){
      if (this.drawer === true){
        this.drawer = !this.drawer
        console.log("test V-click-outside" + this.drawer)
      }
    },
    showMobileDrawer() {
      console.log(this.drawer)
      if(this.drawer) this.drawer = !this.drawer
      this.drawer = !this.drawer

    },
  }
}
</script>
