<template>
  <v-footer
      class="ma-0"
  >
    <v-container>
      <v-row class=" mt-10">
        <v-col cols="12" lg="3" sm="12">
          <p class="logo primary--text">
            WeddingFlow
          </p>
            <a  href="https://app.wedding-flow.de/#/login" target="_blank">
               Anmelden
            </a>
            <a @click="clickTest" href="https://app.wedding-flow.de/#/registrieren" target="_blank">
              Registrieren
            </a>
        </v-col>
        <v-col cols="4" lg="3" sm="4">
          <div>
            <h4 class="mb-3">Rechtliches</h4>
            <router-link to="/datenschutzerklaerung" class="mb-3">Datenschutzerklärung</router-link>
            <a
                href="https://www.iubenda.com/privacy-policy/14714568/cookie-policy"
                class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe mb-3"
                title="Cookie-Richtlinie ">Cookie-Richtlinie</a>
            <router-link to="/agb" class="mb-3"> AGBs / Geschäftsbedingungen</router-link>
            <router-link to="/impressum" class="mb-3">Impressum</router-link>
            <!--<router-link to="/agb">AGB</router-link>-->
          </div>
        </v-col>
        <v-col cols="12" lg="3" sm="4">
          <div>
            <h3>Demo vereinbaren</h3>
            <p>Buche einfach einen Termin und wir zeigen Dir, die Anwendung und ihre Vorteile.</p>
            <v-btn @click="clickDemo" class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" depressed>Demo buchen</v-btn>

          </div>
        </v-col>
        <v-col cols="12" lg="3" sm="4">
          <h3>Offene Fragen?</h3>
          <p class="ma-0">Wir sind gerne für Dich erreichbar.</p>
          <p class="ma-0 pt-2">
            <v-icon class="pe-1 d-inline-flex">mdi-email</v-icon>
            <a class="d-inline-flex" href="mailto:benedikt@wedding-flow.de">benedikt@wedding-flow.de</a>
          </p>
          <p class="ma-0 pt-2">
            <v-icon class="pe-1 d-inline-flex">mdi-phone</v-icon>
            <a class="d-inline-flex" href="tel:+49 1573 9456571">+49 1573 9456571</a>
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <v-col cols="12">
          <p class="">© Copyright {{ new Date().getFullYear() }}. Benedikt Binzer</p>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="dialogDemo"
        :max-width="1200"
    >
      <iframe  width="540" height="1000vh" src="https://meet.sendinblue.com/benedikt-binzer?t=0&l=demo-weddingflow-mit-benedikt-binzer" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; background-color: white"></iframe>
    </v-dialog>
  </v-footer>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      dialogDemo: false
    }
  },
  components: {

  },
  methods: {
    clickTest() {
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');
    },
    clickDemo() {

      this.dialogDemo = true
      // eslint-disable-next-line no-undef
      fbq('track', 'Schedule');

    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  display: block;

  &:hover {
    color: #B19983;
  }
}

.logo {
  font-size: 1.5rem;
}

</style>
