<template>
  <span>
    <v-app-bar
            height="90"
            extension-height=""
            elevate-on-scroll
            shrink-on-scroll
            flat
            app
            fixed
            color="white"
            src="@/assets/landingPage/greyBack.png"
            fade-img-on-scroll
    >
      <b class="align-self-center d-md-flex d-none">
        <v-toolbar-title class="logo pl-lg-5 pr-lg-8 mr-lg-5 d-lg-flex text-center " style="font-size: 1.7rem"
                         @click="$router.push('/')" shrink-on-scroll>
          WeddingFlow
        </v-toolbar-title>
      </b>
      <div class=" pt-3 ps-6 ps-lg-0 align-self-center d-md-flex d-none">
        <p class="mr-6" @click="$router.push('/funktionen')">
          Funktionen
        </p>
        <p class="mr-6" @click="$router.push('/preise');">
          Preise
        </p>
      </div>
      <div class="d-flex d-md-none ps-3 pt-2">
        <v-icon size="1.5em" @click="drawer=true">
          mdi-menu
        </v-icon>
      </div>
      <v-spacer></v-spacer>
      <div class="d-none d-lg-flex align-self-center">
        <a href="https://test.wedding-flow.de/#/login">
          <!--<v-btn color="" class="mr-2 text-uppercase startBtn pt-5 pb-5 ps-6 pe-6" depressed>
            <v-icon color="black">mdi-account</v-icon>
          </v-btn>-->
        </a>
                    <a href="https://app.wedding-flow.de/#/login" target="_blank">

          <v-btn color="text-uppercase startBtn pt-5 pb-5 ps-6 pe-6" depressed class="mr-2"
                 >
          Login
          <v-icon class="ml-2">mdi mdi-account</v-icon>
          </v-btn></a>
        <v-btn color="primary text-uppercase startBtn pt-5 pb-5 ps-6 pe-6" depressed class="mr-2"
               @click="dialogDemo=true">
          Demo buchen
          <v-icon class="ml-2">mdi-calendar</v-icon>
        </v-btn>
      </div>

      <div class="d-flex d-lg-none align-self-center">
          <a href="https://app.wedding-flow.de/#/login" target="_blank">
              <v-btn color="text-uppercase startBtn pt-5 pb-5 ps-6 pe-6" depressed class="mr-2"
                     >
          <v-icon class="ml-2">mdi mdi-account</v-icon>
        </v-btn>
          </a>
        <v-btn color="primary" depressed class="mr-2 primary text-uppercase startBtn pt-5 pb-5 ps-6 pe-6"
               @click="dialogDemo=true">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </div>
      <v-dialog
              v-model="dialogDemo"
              :max-width="1200"
      >
        <iframe width="540" height="1000vh"
                src="https://meet.sendinblue.com/benedikt-binzer?t=0&l=demo-weddingflow-mit-benedikt-binzer"
                frameborder="0" scrolling="auto" allowfullscreen
                style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; background-color: white"></iframe>
      </v-dialog>
    </v-app-bar>
    <v-navigation-drawer
            v-model="drawer"
            fixed
            disable-route-watcher
            class="pa-6 fadeIn"
    >

      <v-list-item
              class="px-2 py-5 fadeIn">
        <v-list-item-title
                class="text-capitalize"
                align="center">
          <v-avatar size="10">
            <v-img></v-img>
          </v-avatar>
          <v-toolbar-title class="logo pl-lg-5 pr-lg-8 mr-lg-5 d-lg-flex text-center " style="font-size: 1.7rem"
                           @click="goTo('/');$vuetify.goTo('#header')" shrink-on-scroll>
            WeddingFlow
          </v-toolbar-title>
        </v-list-item-title>
      </v-list-item>
      <v-list
              nav
              dense
              class="fadeIn">
        <v-list-item-group
                v-model="selectedItem"
                color="primary">
          <v-list-item
                  :id="'navD'+item.text"
                  v-for="(item, i) in items"
                  :key="i"
                  @click="goTo(item.route);$vuetify.goTo('#header')"
          >
            <v-list-item-icon>
              <v-icon
                      v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                      v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>

export default {
    name: "Navbar",
    data() {
        return {
            drawer: false,
            dialogDemo: false,
            selectedItem: 0,
            items: [
                {icon: 'mdi-view-dashboard', text: 'Start', route: '/'},
                {icon: 'mdi-cogs', text: 'Funktionen', route: '/funktionen'},
                {icon: 'mdi-cash', text: 'Preise', route: '/preise'},
            ]
        }
    },
    components: {},
    methods: {

        openRegister() {
            this.$router.push('/registrieren')
        },
        openDialogDemo() {
            this.dialogDemo = true
        },
        goTo(route) {
            this.$router.push(route);
            this.drawer = false
        }
    }
}
</script>

<style scoped>
p {
    display: inline-block;
    position: relative;
}

p:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #6D7E6D;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

p:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

p:hover {
    cursor: pointer !important;
}

v-toolbar-title:hover {
    cursor: pointer !important;
}

</style>
