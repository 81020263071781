<template>
  <div>
    <v-container class="about" style="padding-top: 120px ">
      <v-row class="align-center text-center">
        <v-col cols="12" lg="6" class="pa-md-8" offset-lg="3">
          <h2 class="pb-2 text-subtitle-1">
            Funktionen
          </h2>
          <h1 class="pb-8 text-h4">
            Alle Funktionen im Überblick
          </h1>
        </v-col>
      </v-row>
      <v-row class="align-center text-center justify-center">
        <v-col cols="12" md="6" lg="4" class="pa-md-8"  v-for="funktion of this.functions" :key="funktion.title">
          <v-icon color="tertiary" size="50" class="functionIcon">
            {{ funktion.icon }}
          </v-icon>
          <h3 class="pb-2 pt-4 text-h6">{{ funktion.title }}</h3>
          <p>{{ funktion.content }}</p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Vorteile end -->

    <!-- Vorteile end -->
    <div class="backGroundGrey">
      <v-container class="containerPadding" id="about">
        <v-row class="text-center justify-center">
          <v-col cols="12" md="4" class="">
              <a @click="clickTest" href="https://app.wedding-flow.de/#/registrieren" target="_blank">
                  <v-btn class=" text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
                  >
                      Kostenlos testen
                      <v-icon class="ps-2">
                          mdi-arrow-right
                      </v-icon>
                  </v-btn>
              </a>
          </v-col>
          <v-col cols="12"  md="2" lg="1">
            oder
          </v-col>
          <v-col cols="12" md="4">
            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" @click="clickDemo">
              Jetzt Demo buchen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Contact and Demo start -->
    <div class="" style="background-color: #FAFAFA">
      <v-container class="containerPadding" id="contact">
        <v-row class="text-center pb-8">
          <v-col>
            <h2 class="pb-2 text-subtitle-1 d-block">
              Kontakt
            </h2>
            <h4 class="pb-8 text-h4 d-block">
              Dein Ansprechpartner rund um Fragen zur Anwendung
            </h4>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="6" md="4" class="text-center ">
            <v-img
                :src="require('@/assets/landingPage/profilbild.png')"
                position="bottom"
                max-width="270px"
                width="70%"
                class="d-inline-flex rounded-circle"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" class="ps-md-8">
            <p>Bei offenen Fragen bin ich gerne für Dich erreichbar!</p>
            <h5 class="text-h6">Benedikt Binzer</h5>
            <p>
              <v-icon color="" size="20" class="scale">
                mdi-phone
              </v-icon>
              <a href="tel:+4915739456571">+49 1573 9456571 </a><br>
              <v-icon color="" size="20" class="scale pe-1">
                mdi-email
              </v-icon>
              <a href="mailto:benedikt@wedding-flow.de">benedikt@wedding-flow.de</a>
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="ps-md-8 pt-12 pt-md-0 text-center text-md-start">
            <h4 class="text-h5 pb-3">Demo vereinbaren</h4>
            <p>Buche gerne einen Termin und ich zeige Dir die Anwendung und die Vorteile, die sie
              Dir
              bietet.</p>
            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" @click="clickDemo">
              Termin buchen
            </v-btn>

          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="backGroundGrey">
      <v-container class="containerPadding">
        <v-row class="justify-center align-center text-center">
          <v-col cols="12" md="3">
            <v-img
                :src="require('@/assets/landingPage/climate.svg')"
                max-width="70px"
                width="100%"
                class="d-inline-flex"
            />
          </v-col>
          <v-col cols="12" md="5" class="text-center text-md-start">
            <h2 class="pb-2 text-subtitle-1 d-block">
              Förderung der CO₂-Entnahme
            </h2>
            <h4 class="pb-8 text-h5 d-block">
              Erfahre wie wir gemeinsam mit Dir einen Teil dazu beitragen, dass CO₂ aus der Atmosphäre gefiltert wird.
            </h4>
            <div class="hover">
              <a target="_blank" href="https://climate.stripe.com/nGrMmy" >Mehr Erfahren <v-icon class="ps-2" color="primary">
                mdi-arrow-right
              </v-icon></a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog
        v-model="dialogNewsletter"
        :max-width="550"
    >
      <iframe width="540" height="700vh" src="https://575f9199.sibforms.com/serve/MUIEAEY4uCrZC7IS6TiN-zEAhHFT5bgGJvzh9RKBXR5uH9OtqUnD9yFSLQeNwBiNjpxU9MW2ccgZLEtjzld0s5aRCCWE3DN1ViC3hxv2uWII6tqM70j7HMYgocdpLg053rI-zfiGCQQGfsAnsRXkv-46QakG1aXeN3WVeUrtJNK3P26vCVtTOt9mBnGUb95DdEmNueo4-dPQbK-U" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
    </v-dialog>
    <v-dialog
        v-model="dialogDemo"
        :max-width="1200"
    >
      <iframe  width="540" height="1000vh" src="https://meet.sendinblue.com/benedikt-binzer?t=0&l=demo-weddingflow-mit-benedikt-binzer" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; background-color: white"></iframe>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Functions',
  components: {},
  mounted() {
    window.scrollTo(0, 0)
  },
  methods:{
    clickTest() {
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');
    },
    clickDemo() {
      this.dialogDemo = true
      // eslint-disable-next-line no-undef
      fbq('track', 'Schedule');

    }
  },
  data(){
    return {
      dialogNewsletter: false,
      dialogDemo: false,
      functions: [
        {
          title: 'Dashboard',
          content: 'Das wichtigste zuerst! Anstehende Termine und Hochzeiten, sowie offene Aufgaben auf einen Blick.',
          icon: 'mdi-post',
        },
        {
          title: 'Kunden und Dienstleister',
          content: 'Pflege Deine Kunden und Dienstleister. Verknüpfe Kontakte mit Hochzeiten oder Aufgaben und behalte alles im Blick.',
          icon: 'mdi-account-eye',
        },
        {
          title: 'Rechnungen und Angebote',
          content: 'Erstelle Rechnungen und Angebote für deine Brautpaare und behalte den Überblick über offene Zahlungen.',
          icon: 'mdi mdi-file-document-edit-outline',
        },
        {
          title: 'Budgetpläne',
          content: 'Verwalte das Budget deiner Kunden und tracke die geplanten und tatsächlichen Ausgaben zu jeder Hochzeit.',
          icon: 'mdi-finance',
        },
        {
          title: 'Tagesabläufe',
          content: 'Erstelle Tagesabläufe und ordne die einzelnen Tagespunkte den beteiligten Dienstleistern zu.',
          icon: 'mdi-clock',
        },
        {
          title: 'Aufgabenverwaltung',
          content: 'Durch die ToDo-Liste und die Zuordnung von Aufgaben zu Hochzeiten, weißt Du immer was Du zu tun hast.',
          icon: 'mdi-format-list-checks',
        },
        {
          title: 'Terminkalender',
          content: 'Behalte Termine und Fristen immer im Auge. Viele Ereignisse werden automatisch in deinen Kalender eingetragen.',
          icon: 'mdi-calendar',
        },
        {
          title: 'Dokumente speichern',
          content: 'Lade Dokumente, wie Rechnungen oder Angebote von Dienstleistern, direkt an die entsprechende Hochzeit hoch.',
          icon: 'mdi-file',
        },
        {
          title: 'Vieles mehr',
          content: 'Wir freuen uns auf Dein Feedback und bringen stetig neue Features für Hochzeitsplaner.',
          icon: 'mdi mdi-handshake',
        },

      ],
    }
  }
}

</script>
<style>
.functionIcon{

}
</style>
