import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComingSoon from "@/views/general/HomeComingSoon";
import Plans from "@/views/general/Plans";
import Functions from "@/views/general/Functions";
import NewsletterAnmeldung from "@/views/general/Newsletter-Anmeldung.vue";
import NewsletterBestaetigen from "@/views/general/Newletter-Bestatigen.vue";
import Imprint from "@/views/general/Imprint.vue";


Vue.use(VueRouter)

const routes = [
    /**
     * General Section
     */
    {
        path: '/',
        name: 'Home',
        component: HomeComingSoon
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Imprint
    },
    {
        path: '/preise',
        name: 'Plans',
        component: Plans
    },
    {
        path: '/datenschutzerklaerung',
        name: 'DataProtection',
        component: () => import('@/views/general/DataProtection.vue'),
    },
    {
        path: '/agb',
        name: 'AGB',
        component: () => import('@/views/general/AGB.vue'),
    },
    {
        path: '/funktionen',
        name: 'Funktionen',
        component: Functions
    },
    {
        path: '/newsletter',
        name: 'Newsletter',
        component: NewsletterAnmeldung
    },
    {
        path: '/newsletterMail',
        name: 'Newsletter',
        component: NewsletterBestaetigen
    },
    ]

const router = new VueRouter({
    routes,
  })

export default router
