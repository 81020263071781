import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"about text--center pt-16"},[_c(VRow,{staticClass:"text-md-center"},[_c(VCol,[_c('h1',[_vm._v("Du hast dich erfolgreich zum Newsletter angemeldet.")]),_c('p',[_vm._v("Wenn du dich abmelden möchtest folge einfach den Links auf der entsprechenden E-Mail.")])])],1),_c(VRow,{staticClass:"text-md-center align-content-center"},[_c(VCol,{staticClass:"text-md-center"},[_c(VImg,{staticClass:"rounded-xl d-inline-flex",attrs:{"src":require('@/assets/landingPage/hello.svg'),"max-width":"300px","height":"auto"}})],1)],1),_c(VRow,{staticClass:"text-md-center"},[_c(VCol,[_c(VBtn,{staticClass:"mt-2 primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" Zurück zum Start "),_c(VIcon,{staticClass:"ps-2"},[_vm._v(" mdi-arrow-right ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }