<template>
  <div>
    <v-container id="preise" style="padding-top: 120px">
      <v-row class="align-center text-center">
        <v-col cols="12" lg="6" class="pa-md-8" offset-lg="3">
          <h2 class="pb-2 text-subtitle-1">
            Preise
          </h2>
          <h1 class="pb-8 text-h4">
            Starte jetzt unverbindlich und kostenfrei und wähle später den Plan, der zu Dir passt
          </h1>
        </v-col>
      </v-row>
      <v-row class="align-center  justify-center">
        <v-col cols="12" md="6" lg="5" xl="4" class="pa-md-8">
          <v-card class="pa-8 rounded-xl" flat>
            <h2 class="pb-0 ps-2 text-subtitle-1 ">Essential
            </h2>
            <h4 class="pb-8 ps-2 text-h5">
              Alles was Du zum Planen von Hochzeiten brauchst
            </h4>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-post
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Daten verwalten</v-list-item-title>
                  <v-list-item-subtitle>Hochzeiten, Brautpaare uvm. an einem Ort</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-cogs
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Hochzeiten planen</v-list-item-title>
                  <v-list-item-subtitle>Budgetpläne, Tagesabläufe etc.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-file
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>10 GB Speicher</v-list-item-title>
                  <v-list-item-subtitle>Zum Speichern von Dateien</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-text
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>3 Individuelle Felder</v-list-item-title>
                  <v-list-item-subtitle>Zum Anpassen des Systems</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <div class="text-center pt-8">
              <!--PREIS
             Für 3 Monate
             <p><span class="text-h5 pe-3"><s>19,99€</s></span><span class="text-h4 primary--text">15,97€</span> /
               Monat</p>
             <p>danach
               <span class=" pe-3">19,99€</span></p>-->
                <p><span class="text-h5 primary--text">19,97€ <small>/ Monat</small></span></p>
                <a @click="clickTest" href="https://app.wedding-flow.de/#/registrieren" target="_blank">

                <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6">Kostenlos testen</v-btn>
                </a>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="5" xl="4" class="pa-md-8">
          <v-card class="pa-8 rounded-xl" flat>
            <h2 class="pb-0 ps-2 text-subtitle-1">Premium
            </h2>
            <h4 class="pb-8 ps-2 text-h5">
              Unbegrenzte Planung mit Deinem Team
            </h4>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="">
                    mdi-arrow-up
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Alle Funktionen aus Essential</v-list-item-title>

                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-account-group
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Mehrbenutzerfähig</v-list-item-title>
                  <v-list-item-subtitle>Arbeite gemeinsam mit deinem Team</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-mail
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Externe Einbindungen</v-list-item-title>
                  <v-list-item-subtitle>E-Mail, Termine uvm.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-file
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>50 GB Speicher</v-list-item-title>
                  <v-list-item-subtitle>Zum Speichern von Dateien</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-text
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>20 Individuelle Felder</v-list-item-title>
                  <v-list-item-subtitle>Passe das System beliebig an</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <div class="text-center pt-8">
              <p><span class="text-h5 primary--text">Bald verfügbar</span></p>
              <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" disabled>Kostenlos testen</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-container>


    <v-container class="pt-16">
      <template>
        <v-simple-table class="rounded-xl pa-4 pb-9" >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
              </th>
              <th class="text-center text-h6">
                Essential
              </th>
              <th class="text-center text-h6">
                Premium
              </th>
            </tr>
            </thead>

            <tbody v-for="category in categories"
                   :key="category.headLine"
            >

            <tr style="background-color: #FAFAFA">
              <td class="pt-8 pb-3"><span class="text-h6 ">{{ category.headLine }}</span></td>
              <td class="text-center"></td>
              <td class="text-center"></td>
            </tr>
            <tr
                v-for="item in category.functions"
                :key="item.name"
            >
              <td class="pt-3 pb-3 ">
                <span class="subtitle-1 ps-0 ps-md-6">{{ item.name }}</span> <br v-if="item.desc">
                <p class="ps-0 ps-md-6">{{ item.desc }}</p>
              </td>
              <td class="text-center"><b><span v-if="item.essential ==='X'"><v-icon color="primary">mdi-check</v-icon></span><span v-else-if="item.essential ===''">X</span><span v-else>{{ item.essential }}</span></b></td>
              <td class="text-center"><b><span v-if="item.premium ==='X'"><v-icon color="primary">mdi-check</v-icon></span><span v-else>{{ item.premium }}</span></b></td>
            </tr>
            </tbody>
            <tbody>
            <tr style="background-color: #FAFAFA">
              <td class="pt-8 pb-3"><span class="text-h6 ">Jetzt unverbindlich testen</span></td>
              <td class="text-center"></td>
              <td class="text-center"></td>
            </tr>
            <tr >
              <td class="pt-3 pb-3 ">
                <span class="subtitle-1 ps-0 ps-md-6">Kostenlos testen</span> <br>
                <span class="ps-0 ps-md-6">Starte jetzt ohne Angabe einer Zahlungsmethode und wähle später den Plan, der zu Dir passt.</span>
              </td>
              <td class="text-center"><b></b></td>
              <td class="text-center ">
                <p class="mb-8 mt-8">
                    <a href="https://app.wedding-flow.de/#/registrieren" target="_blank">

                    <v-btn @click="clickTest" class="disabled primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" >
                    Kostenlos testen
                    </v-btn></a><br>

                </p>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-container>

    <!-- Contact and Demo start -->
    <div class="" style="background-color: #FAFAFA">
      <v-container class="containerPadding" id="contact">
        <v-row class="text-center pb-8">
          <v-col>
            <h2 class="pb-2 text-subtitle-1 d-block">
              Kontakt
            </h2>
            <h4 class="pb-8 text-h4 d-block">
              Dein Ansprechpartner rund um Fragen zur Anwendung
            </h4>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="6" md="4" class="text-center ">
            <v-img
                :src="require('@/assets/landingPage/profilbild.png')"
                position="bottom"
                max-width="270px"
                width="70%"
                class="d-inline-flex rounded-circle"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" class="ps-md-8">
            <p>Bei offenen Fragen bin ich gerne für Dich erreichbar!</p>
            <h5 class="text-h6">Benedikt Binzer</h5>
            <p>
              <v-icon color="" size="20" class="scale">
                mdi-phone
              </v-icon>
              <a href="tel:+4915739456571">+49 1573 9456571 </a><br>
              <v-icon color="" size="20" class="scale pe-1">
                mdi-email
              </v-icon>
              <a href="mailto:benedikt@wedding-flow.de">benedikt@wedding-flow.de</a>
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="ps-md-8 pt-12 pt-md-0 text-center text-md-start">
            <h4 class="text-h5 pb-3">Demo vereinbaren</h4>
            <p>Buche gerne einen Termin und ich zeige Dir die Anwendung und die Vorteile, die sie
              Dir
              bietet.</p>
            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" @click="clickDemo">
              Termin buchen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="backGroundGrey">
      <v-container class="containerPadding">
        <v-row class="justify-center align-center text-center">
          <v-col cols="12" md="3">
            <v-img
                :src="require('@/assets/landingPage/climate.svg')"
                max-width="70px"
                width="100%"
                class="d-inline-flex"
            />
          </v-col>
          <v-col cols="12" md="5" class="text-center text-md-start">
            <h2 class="pb-2 text-subtitle-1 d-block">
              Förderung der CO₂-Entnahme
            </h2>
            <h4 class="pb-8 text-h5 d-block">
              Erfahre wie wir gemeinsam mit Dir einen Teil dazu beitragen, dass CO₂ aus der Atmosphäre gefiltert wird.
            </h4>
            <div class="hover">
              <a target="_blank" href="https://climate.stripe.com/nGrMmy" >Mehr Erfahren <v-icon class="ps-2" color="primary">
                mdi-arrow-right
              </v-icon></a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog
        v-model="dialogNewsletter"
        :max-width="550"
    >
      <iframe width="540" height="700vh" src="https://575f9199.sibforms.com/serve/MUIEAEY4uCrZC7IS6TiN-zEAhHFT5bgGJvzh9RKBXR5uH9OtqUnD9yFSLQeNwBiNjpxU9MW2ccgZLEtjzld0s5aRCCWE3DN1ViC3hxv2uWII6tqM70j7HMYgocdpLg053rI-zfiGCQQGfsAnsRXkv-46QakG1aXeN3WVeUrtJNK3P26vCVtTOt9mBnGUb95DdEmNueo4-dPQbK-U" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
    </v-dialog>
    <v-dialog
        v-model="dialogDemo"
        :max-width="1200"
    >
      <iframe  width="540" height="1000vh" src="https://meet.sendinblue.com/benedikt-binzer?t=0&l=demo-weddingflow-mit-benedikt-binzer" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; background-color: white"></iframe>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Plans',
  components: {},
  mounted() {
    window.scrollTo(0, 0)
  },
  methods:{
    clickTest() {
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');
    },
    clickDemo() {
      this.dialogDemo = true
      // eslint-disable-next-line no-undef
      fbq('track', 'Schedule');

    }
  },
  data() {
    return {
      dialogDemo: false,
      dialogNewsletter: false,
      categories: [
        {
          headLine: 'Daten verwalten',
          functions: [
            {
              name: 'Brautpaare und Dienstleister verwalten',
              desc: '',
              essential: 'X',
              premium: 'X',
            },
            {
              name: 'Aufgaben verwalten',
              essential: 'X',
              premium: 'X',
            },
            {
              name: 'Termine verwalten',
              essential: 'X',
              premium: 'X',
            },

          ]
        },
        {
          headLine: 'Hochzeiten planen',
          functions: [
            {
              name: 'Dateien hochladen',
              essential: '10GB',
              premium: '50GB',
            },
            {
              name: 'Budgetpläne',
              essential: 'X',
              premium: 'X',
            },
            {
              name: 'Tagesabläufe',
              essential: 'X',
              premium: 'X',
            },
          ]
        },
        {
          headLine: 'Zusammenarbeit',
          functions: [
            {
              name: 'Teammitglieder hinzufügen',
              desc: '',
              essential: '',
              premium: 'X',
            },
            {
              name: 'Datensätze mit dem Team teilen',
              desc: 'Jedes Teammitglied kann selbst entscheiden, ob die erstellten Datensätze von anderen angesehen und bearbeitet werden können.',
              essential: '',
              premium: 'X',
            }
          ]
        },
        {
          headLine: 'Anpassungen',
          functions: [
            {
              name: 'Kategorien und Statuswerte bearbeiten',
              desc: 'Statuswerte und Kategorien hinterlegen und das System so an deinen Arbeitsablauf anpassen.',
              essential: 'X',
              premium: 'X',
            },
            {
              name: 'Individuelle Felder',
              desc: 'Passe die Felder an, die Du in den unterschiedlichen Datensätzen speichern möchtest und ergänze das System um Informationen die Dir wichtig sind.',
              essential: 'max. 3',
              premium: 'X',
            },

          ]
        }
      ]
    }
  }
}

</script>
